

export function Months(){
    return ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"];
}

export function ConvertDate(date){
    // Data originale
    let dataOriginale = new Date(date);

    // Estrai giorno, mese e anno
    let giorno = dataOriginale.getDate();
    let mese = Months()[dataOriginale.getMonth()];
    let anno = dataOriginale.getFullYear();

    // Crea la stringa formattata
    let dataFormattata = `${giorno} ${mese} ${anno}`;

    return dataFormattata;
}