import React from 'react'
// import ReactPageScroller from 'react-page-scroller';
import Header from './components/NavBar';
import {AlertContent} from '../../components/Alert';
import Footer from '../../components/footer';

function Alerts(){

    return (
        <div className='bg-white'>
            <Header />

            <div className='container mx-auto px-4 my-10'>
                {/* <h1 className='text-4xl font-extrabold text-center'>Nuovi aggiornamenti e comunicazioni</h1>
                <p className='text-gray-700 text-center text-lg'>Dare un'occhiata su questa sessione non è una perdita di tempo ;), resta aggiornato per sviluppi di Ethernial e comunicazioni sullo stato dei server.</p> */}

                <p className='text-4xl font-extrabold text-center my-24'>Coming Soon</p>

                {/* <div className='grid grid-flow-row auto-rows-max gap-28 justify-center w-auto mx-auto mt-24'>
                    <article className='lg:flex-row gap-8 flex-col flex isolate relative'>
                        <div className='lg:shrink-0 lg:w-64 lg:aspect-square sm:aspect-[2/1] aspect-[16/9] relative border-gray-900/20 border rounded-2xl overflow-hidden'>
                            <img alt="" src="https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=3603&amp;q=80" class="object-cover bg-gray-50  w-full h-full inset-0 absolute " />
                        </div>
                        <div>
                            <div className='text-xs gap-4 items-center flex'>
                                <time datetime="2020-03-16" class="text-gray-500">Mar 16, 2020</time>
                                <a href="#" class="inline-flex items-center rounded-full bg-gray-50 py-1.5  px-3 text-xs font-medium text-gray-600">Marketing</a>
                            </div>
                            <div class="max-w-xl relative">
                                <h3 class="text-gray-900 leading-6 font-semibold text-lg mt-3">
                                    <a href="#">
                                        <span class="inset-0  absolute"></span>
                                        Boost your conversion rate
                                    </a>
                                </h3>
                                <p class="text-gray-600 leading-6 text-sm mt-5">Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel iusto corrupti dicta laboris incididunt.</p>
                            </div>
                            <div class="pt-6 border-gray-900/5 border-t  flex mt-6">
                                <div class="gap-4  flex items-center relative">
                                    <img alt="" src="https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=2&amp;w=256&amp;h=256&amp;q=80" class=" bg-gray-50 rounded-full  w-10  h-10 "/>
                                    <div class="leading-6 text-sm">
                                        <p class="text-gray-900 font-semibold">
                                            <a href="#">
                                                <span class="inset-0 absolute"></span>Michael Foster
                                            </a>
                                        </p>
                                        <p class="text-gray-600">Co-Founder / CTO</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>

                    <article className='lg:flex-row gap-8 flex-col flex isolate relative'>
                        <div className='lg:shrink-0 lg:w-64 lg:aspect-square sm:aspect-[2/1] aspect-[16/9] relative border-gray-900/20 border rounded-2xl overflow-hidden'>
                            <img alt="" src="https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=3603&amp;q=80" class="object-cover bg-gray-50  w-full h-full inset-0 absolute " />
                        </div>
                        <div>
                            <div className='text-xs gap-4 items-center flex'>
                                <time datetime="2020-03-16" class="text-gray-500">Mar 16, 2020</time>
                                <a href="#" class="inline-flex items-center rounded-full bg-gray-50 py-1.5  px-3 text-xs font-medium text-gray-600">Marketing</a>
                            </div>
                            <div class="max-w-xl relative">
                                <h3 class="text-gray-900 leading-6 font-semibold text-lg mt-3">
                                    <a href="#">
                                        <span class="inset-0  absolute"></span>
                                        Boost your conversion rate
                                    </a>
                                </h3>
                                <p class="text-gray-600 leading-6 text-sm mt-5">Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel iusto corrupti dicta laboris incididunt.</p>
                            </div>
                            <div class="pt-6 border-gray-900/5 border-t  flex mt-6">
                                <div class="gap-4  flex items-center relative">
                                    <img alt="" src="https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=2&amp;w=256&amp;h=256&amp;q=80" class=" bg-gray-50 rounded-full  w-10  h-10 "/>
                                    <div class="leading-6 text-sm">
                                        <p class="text-gray-900 font-semibold">
                                            <a href="#">
                                                <span class="inset-0 absolute"></span>Michael Foster
                                            </a>
                                        </p>
                                        <p class="text-gray-600">Co-Founder / CTO</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
                <div className="text-center flex justify-center items-center mt-10">
                    <a href="" className='text-md hover:underline'><CaretLeft /></a>
                    <a href="" className='text-md hover:underline mx-3'>1</a>
                    <a href="" className='text-md hover:underline '><CaretRight /></a>
                </div> */}
            </div>

            <AlertContent direction="bottom-right"></AlertContent>
            <Footer />
        </div>
    );
}

export default Alerts;