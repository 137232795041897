'use client'

import { useState } from 'react'
import { Dialog, DialogPanel } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import Logo from '../../../../assets/img/logo.png';
import Bg from '../../../../assets/img/LandingBk.png';
import {SignOut } from '@phosphor-icons/react';
import {logout} from '../../../../components/RequireAuth';
import { Link } from 'react-router-dom';




export default function Example() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    
    
    const navigation = [
      { name: 'Home', href: '/Dashboard', active: window.location.pathname == "/Dashboard" },
      { name: 'Server List', href: '/ServerList', active: window.location.pathname == "/ServerList" },
      { name: 'Impostazioni', href: '/Settings', active: window.location.pathname == "/Settings" },
      { name: 'News', href: '/Alerts', active: window.location.pathname == "/Alerts" },
      { name: <SignOut size="23px" weight="bold" />, href: '/Logout', active: false, class: "text-[#676767] cursor-pointer", function: logout },
    ]

  return (
    <div className="bg-white ">
        <header className="relative inset-x-0 top-0 z-50">
            <nav aria-label="Global" className=" relative top-0 left-0 w-full flex items-center justify-between p-6 lg:px-8 after:absolute after:left-40 after:bottom-1 after:mx-6 after:w-[86%] after:md:block after:hidden after:border-b after:border-[#eaca9f]">
                <div className="flex lg:flex-1 md:md-0 mx-auto">
                    <Link to="/" className="-m-1.5 p-1.5">
                        <span className="sr-only">Ethernial</span>
                        <img
                            alt=""
                            src={Logo}
                            className="md:h-28 h-14 w-auto md:absolute z-10 md:top-10 "
                        />
                    </Link>
                </div>
                <div className="flex lg:hidden">
                    <button
                    type="button"
                    onClick={() => setMobileMenuOpen(true)}
                    className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                    >
                    <span className="sr-only">Open main menu</span>
                        <Bars3Icon aria-hidden="true" className="h-6 w-6" />
                    </button>
                </div>
                <div className="hidden lg:flex lg:gap-x-12 ">
                    {navigation.map((item) => {

                        if(item.function){
                            
                            return (
                                <Link key={item.name} onClick={item.function} className={["text-lg relative font-semibold leading-6  after:absolute after:top-12 after:w-2 after:h-2 after:inset-2/4 after:rounded-full", item.class, item.active ? "after:bg-[#eaca9f] text-[#eaca9f]" : "text-gray-400 hover:text-gray-200"].join(" ")}>
                                    {item.name}
                                </Link>
                            )    
                        }else

                        return (
                            <Link key={item.name} to={item.href}  className={["text-lg relative font-semibold leading-6  after:absolute after:top-12 after:w-2 after:h-2 after:inset-2/4 after:rounded-full", item.class, item.active ? "after:bg-[#eaca9f] text-[#eaca9f]" : "text-gray-400 hover:text-gray-200"].join(" ")}>
                                {item.name}
                            </Link>
                        )
                    })}
                </div>
                <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                    <a href="" className="w-0 !p-0 bg-no-repeat bg-cover h-10 active:bg-[center_top_-84px] hover:bg-[center_top_-42px] px-3 py-1 rounded-md text-lg font-semibold leading-6 text-transparent ">
                        &nbsp;
                    </a>
                </div>
            </nav>
            <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
                <div className="fixed inset-0 z-50" />
                <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="flex items-center justify-between ">
                        <div className='mx-auto'>
                            <Link to="/" className="">
                                <span className="sr-only">Ethernial</span>
                                <img
                                    alt=""
                                    src={Logo}
                                    className="h-14 w-auto z-10 "
                                />
                            </Link>
                        </div>
                        <button
                            type="button"
                            onClick={() => setMobileMenuOpen(false)}
                            className="-m-2.5 rounded-md p-2.5 text-gray-700"
                        >
                            <span className="sr-only">Close menu</span>
                            <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                        </button>
                        </div>
                        <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-gray-500/30">
                            <div className="space-y-2 py-6">
                            {navigation.map((item) => {

                                if(item.function){
                                    
                                    return (
                                        <Link key={item.name} onClick={item.function} className={["-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50", item.class,  "text-gray-400 hover:text-gray-200"].join(" ")}>
                                            {item.name}
                                        </Link>
                                    )    
                                }else

                                return (
                                    <Link
                                        key={item.name}
                                        to={item.href}
                                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                    >
                                        {item.name}
                                    </Link>
                                )
                            })}
                            </div>
                        </div>
                    </div>
                </DialogPanel>
            </Dialog>
        </header>

    </div>
  )
}
