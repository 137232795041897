import axios from 'axios';

export function UpdatePassword(oldPassword, newPassword, repeatNewPassword){
    const userLog = JSON.parse(localStorage.getItem('user')).token;

    return axios.put(process.env.REACT_APP_ENDPOINT_API + '/api/v1/user/settings/updatePassword',{
        "oldpassword": oldPassword,
        "newPassword": newPassword,
        "repeatNewPassword": repeatNewPassword
    },{
        headers: {
            'Authorization': `Bearer ${userLog}`
        }
    });
}

export function UpdateInfo(username, email, password){
    const userLog = JSON.parse(localStorage.getItem('user')).token;

    return axios.put(process.env.REACT_APP_ENDPOINT_API + '/api/v1/user/settings/update',{
        "name": username,
        "email": email,
        "password": password
    },{
        headers: {
            'Authorization': `Bearer ${userLog}`
        }
    });
}