import React, {useState} from 'react'
import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid'
import { UpdatePassword,UpdateInfo } from '../../../../requests/User.request';
import { AlertC} from '../../../../components/Alert';
import {GetErrors} from '../../../../scripts/errors';
import {getUser} from '../../../../components/RequireAuth';

export default function Example() {
    const [username, setUsername] = useState(getUser().user.name);
    
    const [oldPassword, setOldPassowrd] = useState("");
    const [currentPassword, setCurrentPassword] = useState("");
    const [repeatpassword, setRepeatPassword] = useState("");
    const [email, setEmail] = useState(getUser().user.email);
    const [notyEmail, setNotyEmail] = useState(true);
    const [notyOffer, setNotyOffer] = useState(true);
    const [loadingChangeP, setLoadingChangeP] = useState(false);
    const [loadignUpdateInfo, setLoadignUpdateInfo] = useState(false);
    

    const onUpdateInfo = (e) => {
        console.log(username, email, currentPassword, notyOffer, notyEmail);

        if(loadignUpdateInfo) return;
        setLoadignUpdateInfo(true);

        UpdateInfo(username, email, currentPassword).then(e => {
            AlertC({
                variant: "success",
                children: e.data.message,
                timeOut: 4000
            });

            setLoadignUpdateInfo(false);
        }).catch(error => {

            if(error.status === 422){
                AlertC({
                    variant: "danger",
                    children: GetErrors(error.response.data.errors),
                    timeOut: 4000
                });
            }else if(error.status === 400){
                AlertC({
                    variant: "danger",
                    children: error.response.data.message,
                    timeOut: 4000
                });
            }else{
                AlertC({
                    variant: "danger",
                    children: "Network Error",
                    timeOut: 4000
                });
            }

            setLoadignUpdateInfo(false);
        });
    }

    const onUpdatePassword = (e) => {
        if(loadingChangeP) return;
        setLoadingChangeP(true);

        UpdatePassword(oldPassword, currentPassword, repeatpassword).then(e => {
            AlertC({
                variant: "success",
                children: e.data.message,
                timeOut: 4000
            });

            setOldPassowrd("");
            setCurrentPassword("");
            setRepeatPassword("");

            setLoadingChangeP(false);
        }).catch(error => {

            if(error.status === 422){
                AlertC({
                    variant: "danger",
                    children: error.response.data.message,
                    timeOut: 4000
                });
            }else if(error.status === 400){
                AlertC({
                    variant: "danger",
                    children: error.response.data.message,
                    timeOut: 4000
                });
            }else{
                AlertC({
                    variant: "danger",
                    children: "Network Error",
                    timeOut: 4000
                });
            }

            setLoadingChangeP(false);
        });
    }

  return (
    <>
        <form onSubmit={(e) => { e.preventDefault(); }}>
        <div className="space-y-12">
            <div className="border-b border-gray-900/10 pb-12">
                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="sm:col-span-4">
                        <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                            Username
                        </label>
                        <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                <input
                                    id="username"
                                    name="username"
                                    type="text"
                                    placeholder="janesmith"
                                    defaultValue={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    autoComplete="username"
                                    className="block flex-1 border-0 bg-transparent py-1.5 pl-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="sm:col-span-4">
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                            Email
                        </label>
                        <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                <input
                                    id="email"
                                    name="email"
                                    type="text"
                                    defaultValue={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="user@email.com"
                                    autoComplete="email"
                                    className="block flex-1 border-0 bg-transparent py-1.5 pl-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                    </div>

                    <div className={["sm:col-span-4", email.length > 0 || username.length > 0 ? "block" : "hidden"].join(" ")}>
                        <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                            Password Attuale
                        </label>
                        <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    onChange={(e) => setCurrentPassword(e.target.value)}
                                    required
                                    placeholder="*****"
                                    autoComplete="password"
                                    className="block flex-1 border-0 bg-transparent py-1.5 pl-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                    </div>

                    {/* <div className="col-span-full">
                    <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                        About
                    </label>
                    <div className="mt-2">
                        <textarea
                        id="about"
                        name="about"
                        rows={3}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        defaultValue={''}
                        />
                    </div>
                    <p className="mt-3 text-sm leading-6 text-gray-600">Write a few sentences about yourself.</p>
                    </div>

                    <div className="col-span-full">
                    <label htmlFor="photo" className="block text-sm font-medium leading-6 text-gray-900">
                        Photo
                    </label>
                    <div className="mt-2 flex items-center gap-x-3">
                        <UserCircleIcon aria-hidden="true" className="h-12 w-12 text-gray-300" />
                        <button
                        type="button"
                        className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        >
                        Change
                        </button>
                    </div>
                    </div>

                    <div className="col-span-full">
                    <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
                        Cover photo
                    </label>
                    <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                        <div className="text-center">
                        <PhotoIcon aria-hidden="true" className="mx-auto h-12 w-12 text-gray-300" />
                        <div className="mt-4 flex text-sm leading-6 text-gray-600">
                            <label
                            htmlFor="file-upload"
                            className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                            >
                            <span>Upload a file</span>
                            <input id="file-upload" name="file-upload" type="file" className="sr-only" />
                            </label>
                            <p className="pl-1">or drag and drop</p>
                        </div>
                        <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 10MB</p>
                        </div>
                    </div>
                    </div> */}
                </div>
            </div>

            

            {/* <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-gray-900">Personal Information</h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">Use a permanent address where you can receive mail.</p>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-3">
                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                    First name
                </label>
                <div className="mt-2">
                    <input
                    id="first-name"
                    name="first-name"
                    type="text"
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                </div>
                </div>

                <div className="sm:col-span-3">
                <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                    Last name
                </label>
                <div className="mt-2">
                    <input
                    id="last-name"
                    name="last-name"
                    type="text"
                    autoComplete="family-name"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                </div>
                </div>

                <div className="sm:col-span-4">
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                    Email address
                </label>
                <div className="mt-2">
                    <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                </div>
                </div>

                <div className="sm:col-span-3">
                <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                    Country
                </label>
                <div className="mt-2">
                    <select
                    id="country"
                    name="country"
                    autoComplete="country-name"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                    >
                    <option>United States</option>
                    <option>Canada</option>
                    <option>Mexico</option>
                    </select>
                </div>
                </div>

                <div className="col-span-full">
                <label htmlFor="street-address" className="block text-sm font-medium leading-6 text-gray-900">
                    Street address
                </label>
                <div className="mt-2">
                    <input
                    id="street-address"
                    name="street-address"
                    type="text"
                    autoComplete="street-address"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                </div>
                </div>

                <div className="sm:col-span-2 sm:col-start-1">
                <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900">
                    City
                </label>
                <div className="mt-2">
                    <input
                    id="city"
                    name="city"
                    type="text"
                    autoComplete="address-level2"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                </div>
                </div>

                <div className="sm:col-span-2">
                <label htmlFor="region" className="block text-sm font-medium leading-6 text-gray-900">
                    State / Province
                </label>
                <div className="mt-2">
                    <input
                    id="region"
                    name="region"
                    type="text"
                    autoComplete="address-level1"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                </div>
                </div>

                <div className="sm:col-span-2">
                <label htmlFor="postal-code" className="block text-sm font-medium leading-6 text-gray-900">
                    ZIP / Postal code
                </label>
                <div className="mt-2">
                    <input
                    id="postal-code"
                    name="postal-code"
                    type="text"
                    autoComplete="postal-code"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                </div>
                </div>
            </div>
            </div> */}

            {/* <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-gray-900">Notifiche</h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
                Ti informeremo sempre sui cambiamenti importanti, ma sarai tu a scegliere quali altri argomenti desideri conoscere.
            </p>

            <div className="mt-10 space-y-10">
                <fieldset>
                    <legend className="text-sm font-semibold leading-6 text-gray-900">Tramite Email</legend>
                    <div className="mt-6 space-y-6">
                        <div className="relative flex gap-x-3">
                            <div className="flex h-6 items-center">
                                <input
                                    id="updates"
                                    name="updates"
                                    onChange={(e) => setNotyEmail(e.target.checked)}
                                    defaultChecked={true}
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                />
                            </div>
                            <div className="text-sm leading-6">
                                <label htmlFor="updates" className="font-medium text-gray-900">
                                    Aggiornamenti
                                </label>
                                <p className="text-gray-500">Ricevi una notifica quando qualcuno pubblica un commento su un post.</p>
                            </div>
                        </div>
                        <div className="relative flex gap-x-3">
                            <div className="flex h-6 items-center">
                                <input
                                    id="offers"
                                    name="offers"
                                    onChange={(e) => setNotyOffer(e.target.checked)}
                                    defaultChecked={true}
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                />
                            </div>
                            <div className="text-sm leading-6">
                                <label htmlFor="offers" className="font-medium text-gray-900">
                                    Offerte
                                </label>
                                <p className="text-gray-500">Ricevi una notifica quando un candidato accetta o rifiuta un'offerta.</p>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
            </div> */}
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
                <button
                    onClick={onUpdateInfo}
                    type="submit"
                    disabled={username.length >0 && email.length > 0 && currentPassword.length >= 6 ? false : true}
                    className="flex rounded-md disabled:bg-gray-400 bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                    {
                        loadignUpdateInfo ? 
                            <svg className={"animate-spin -ml-1 mr-3 h-5 w-5 text-white"} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        : <></>
                    }
                    Aggiorna 
                </button>
        </div>
        </form>

        <form className='mt-10'  onSubmit={(e) => { e.preventDefault(); }}>
            <div className="space-y-12">

                <div className="border-b border-gray-900/10 pb-12">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">Autenticazione</h2>
                    <p className="mt-1 text-sm leading-6 text-gray-600">Aggiorna i dati di Autenticazione.</p>

                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-6">
                            <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                                Vecchia password
                            </label>
                            <div className="mt-2">
                                <input
                                id="repeatpassword"
                                name="repeatpassword"
                                onChange={(e) => setOldPassowrd(e.target.value)}
                                type="password"
                                autoComplete="repeatpassword"
                                className="block w-full pl-3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-3">
                            <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                                Nuova Password
                            </label>
                            <div className="mt-2">
                                <input
                                id="password"
                                name="password"
                                disabled={oldPassword.length >= 6 ? false : true}
                                onChange={(e) => setCurrentPassword(e.target.value)}
                                type="password"
                                autoComplete="password"
                                className="block w-full pl-3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-3">
                            <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                                Ripeti la nuova password
                            </label>
                            <div className="mt-2">
                                <input
                                id="repeatpassword"
                                disabled={oldPassword.length >= 6 ? false : true}
                                name="repeatpassword"
                                onChange={(e) => setRepeatPassword(e.target.value)}
                                type="password"
                                autoComplete="repeatpassword"
                                className="block w-full pl-3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="mt-6 flex items-center justify-end gap-x-6">
                <button
                    onClick={onUpdatePassword}
                    type="submit"
                    disabled={currentPassword.length >= 6 && repeatpassword.length >= 6 ? false : true}
                    className="flex rounded-md disabled:bg-gray-400 bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                    {
                        loadingChangeP ? 
                            <svg className={"animate-spin -ml-1 mr-3 h-5 w-5 text-white"} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        : <></>
                    }
                    Aggiorna Password
                </button>
            </div>
    </form>
    </>
  )
}
