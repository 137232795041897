export function GetErrors(json) {
    let errorMessages = [];

    for (let field in json.errors) {
        if (json.errors.hasOwnProperty(field)) {
            errorMessages = errorMessages.concat(json.errors[field]);
        }
    }

    return errorMessages.join(', ');
}
