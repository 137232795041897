import React from 'react'
// import ReactPageScroller from 'react-page-scroller';
import Header from './components/NavBar';
import {AlertContent,  AlertC} from '../../components/Alert';
import Footer from '../../components/footer';

function Landing(){

    return (
        <div>
            <Header />
            <AlertContent direction="bottom-right"></AlertContent>
            <Footer />
        </div>
    );
}

export default Landing;