// import ReactPageScroller from 'react-page-scroller';
import React, {useState, useEffect} from 'react'
import Header from './components/NavBar';
import {AlertContent,  AlertC} from '../../components/Alert';
import {ServerUserList} from '../../requests/Server.request';
import Auth from '../../requests/Auth.request';
import {ConvertDate} from '../../scripts/date';
import {CircleNotch } from '@phosphor-icons/react';
import Footer from '../../components/footer';


function Dashboard(){

    const [ serverList, setServerList ] = useState([]);
    const [ serverLoading, setServerLoading ] = useState([]);

    useEffect(() => {
        ServerUserList().then(e => {
            setServerList(e.data.data);
            setServerLoading(new Array(e.data.data.length).fill(false));
        }).catch(error => {
            console.log(error);
        })

    }, [])
    
    const chooseServer = (svr, i) => { 
        if(serverLoading[i] == true) return;
        
        
        Auth.firstLogin(svr.server_info.serv_token)
        .then((response) => {   
            const newServerLoading = [...serverLoading];
            newServerLoading[i] = true;
            setServerLoading(newServerLoading);

            // Update the serverLists state with the data from the response
            window.location.href = process.env.REACT_APP_PROTOCOL + svr.server_info.serv_token + ".ethernial.it/?pg=Index&act=StartSession&token="+response.data.res.content.token;
        })
        .catch(err => {
            // Handle errors if any
            console.log(err);
            const newServerLoading = [...serverLoading];
            newServerLoading[i] = false;
            setServerLoading(newServerLoading);
            // You may want to display an error message to the user
        });
    }

   

    return (
        <div className='bg-white'>
            <Header />

            <div className='container mx-auto px-4 my-10'>
                <h1 className='text-4xl font-extrabold text-center'>Dashboard</h1>
                <p className='text-gray-700 text-center text-lg'>Benvenuto su Ethernial, qui verranno mostrate le tue sessioni di gioco.</p>

                <div className='md:w-[550px] w-auto mx-auto'>
                    {   
                        serverList.map((item, i) => {
                            
                            return <div onClick={() => chooseServer(item, i)} key={i} className='border-b border-gray-300 my-4 pb-4 relative select-none'>
                                        <div className="border-l-4 hover:border-[#eaca9f] pl-3 cursor-pointer">
                                            <h3 className='text-lg font-medium'>{item.server_info.serv_name}</h3>
                                            <p className=''>Registrato dal: <span className='text-green-400 font-medium'>{ConvertDate(item.svr_date_started)}</span></p>
                                            <p className=''>Aperto dal: <span className='text-gray-600 font-medium'>{ConvertDate(item.server_info.serv_date_start != null ? item.server_info.serv_date_start : item.server_info.created_at)}</span></p>
                                            {item.server_info.serv_date_end == null ? <></> : <p className=''>Chiude il: <span className='text-orange-600 font-medium'>{ConvertDate(item.server_info.serv_date_end)}</span></p>}
                                            
                                            <CircleNotch className={["animate-spin h-5 w-5 mr-3 absolute right-5 top-7", serverLoading[i] ? "block" : "hidden"].join(" ")}  />
                                        </div>
                                    </div>

                            return <div onClick={() => chooseServer(item)} key={i} className='border-b border-gray-300 my-4 pb-4'>
                                <div className="border-l-4 hover:border-[#eaca9f] pl-3 cursor-pointer">
                                    <h3 className='text-lg font-medium'>{item.server_info.serv_name}</h3>
                                    <p className=''>Stato: {item.server_info.serv_status == 1 ? <span className='text-green-400 font-medium'>Attivo</span> : <span className='text-red-400 font-medium'>Chiuso</span>}</p>
                                    {
                                        item.server_info.serv_date_start != null ? 
                                            <p className=''>Aperto dal: <span className='text-gray-600 font-medium'>{item.server_info.serv_date_start}</span></p>
                                            : 
                                            <></>
                                    }
                                    {
                                        item.server_info.serv_date_end != null ? 
                                            <p className=''>Chiude il <span className='text-gray-600 font-medium'>{item.server_info.serv_date_start}</span></p>
                                            : 
                                            <></>
                                    }
                                </div>
                            </div>
                        })
                    }
                    
                </div>
            </div>

            <AlertContent direction="bottom-right"></AlertContent>
            <Footer />
        </div>
    );
}

export default Dashboard;