import React from 'react'
// import ReactPageScroller from 'react-page-scroller';
import Header from './components/NavBar';
import {AlertContent,  AlertC} from '../../components/Alert';
import SettingsForm from './components/Form';
import Footer from '../../components/footer';


function Dashboard(){

    return (
        <div className='bg-white'>
            <Header />

            <div className='container mx-auto px-4 my-10'>
                <h1 className='text-4xl font-extrabold text-center'>Impostazioni</h1>
                <p className='text-gray-700 text-center text-lg'>Modifica le tue informazioni personali.</p>

                <div className='md:w-[550px] w-auto mx-auto'>
                    <SettingsForm />
                </div>
            </div>

            <AlertContent direction="bottom-right"></AlertContent>
            <Footer />
        </div>
    );
}

export default Dashboard;