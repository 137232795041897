import axios from 'axios';

export function ServerUserList(){
    const userLog = JSON.parse(localStorage.getItem('user')).token;

    return axios.get(process.env.REACT_APP_ENDPOINT_API + '/api/v1/user/servers/list', {
        headers: {
            'Authorization': `Bearer ${userLog}`
        }
    });
}