import React, {useState, useEffect} from 'react'
import {X, Info,XCircle,Warning  } from '@phosphor-icons/react';

let allerts = [];

function Variant(key){

    let result;

    switch (key) {
        case "info":
            result = "bg-blue-400 text-blue-800"
            break;

        case "danger":
            result = "bg-red-400 text-red-800"
            break;

        case "warning":
            result = "bg-orange-400 text-orange-800"
            break;
    
        default:
            result = "bg-gray-400 text-gray-800"
            break;
    }

    return result;
}

export function AlertContent(props){
    
    const [alerts, setAlerts] = useState([]);

    useEffect(() => {
        const handleAlertsUpdated = () => {
            setAlerts([...allerts]);
        };

        window.addEventListener('alertsUpdated', handleAlertsUpdated);

        return () => {
            window.removeEventListener('alertsUpdated', handleAlertsUpdated);
        };
    }, [allerts]);

    let direction;

    switch (props.direction) {
        case "top-left":
            direction = "top-0 left-0";
            break;
        case "top-center":
            direction = "top-0 inset-2/4 -translate-x-2/4";
            break;
        case "top-right":
            direction = "top-0 right-0";
            break;
        case "bottom-left":
            direction = "bottom-0 left-0";
            break;
        case "bottom-center":
            direction = "bottom-0 inset-x-1/2 -translate-x-2/4";
            break;
        case "bottom-right":
            direction = "bottom-0 right-0";
            break;
        default:
            direction = "top-0 left-0";
            break;
    }

    return <div className={["fixed grid gap-4 p-4 w-auto z-[9999]", direction].join(" ")}>
            {props.children}
            {alerts.map((item) => {
                
                return <Alert key={item.time} time={item.time} variant={item.variant} timeOut={item.timeOut}>{item.children}</Alert>;
            })}
        </div>
}

export function AlertC(props){

    props.time = new Date().getTime();
    allerts.push(props);

    const event = new Event('alertsUpdated');
    window.dispatchEvent(event);
}

export function Alert(props){

    let icon_active = props.icon_active == undefined ? true : props.icon_active;

    const closeAlertF = (e) => {
        e.preventDefault();

        allerts = allerts.filter(item => item.time != props.time);
        const event = new Event('alertsUpdated');
        window.dispatchEvent(event);
    }

    
    setTimeout(() => {
        
        allerts = allerts.filter(item => item.time != props.time);
        const event = new Event('alertsUpdated');
        window.dispatchEvent(event);
    }, props.timeOut);

    return (
        <div className={[' transition ease-in-out delay-150  w-full px-4 py-3 rounded-md min-w-[300px] z-50', props.className, Variant(props.variant)].join(" ")} >
            <div className='flex items-center'>
                <div>{
                    icon_active ? 
                        props.variant == "info" ? <Info className='' size="20" /> : props.variant == "danger" ? <XCircle className='' size="20" /> : props.variant == "warning" ? <Warning  className='' size="20" /> : <></>
                    : <></>
                }</div>
                <div className={[icon_active ? "ml-3" : "", ""].join(" ")}>{props.children}</div>
                <div className='ml-auto'>
                    <a href="#" onClick={closeAlertF}>
                        <X className='' size="20" />
                    </a>
                </div>
            </div>
        </div>
    );

}
